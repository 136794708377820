var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9047cb23c34a0180d0f853701ade824639c55ac1"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import PackageJson from 'package.json';

Sentry.init({
  dsn: 'https://c3d0b347f01c4320982c9c8a105392c5@o571547.ingest.sentry.io/6394366',
  tracesSampleRate: 1.0,
  release: PackageJson.version,
  attachStacktrace: true,
  enabled: process.env.NODE_ENV === 'production',
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()]
});
