import React, {
  FC,
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import * as Sentry from '@sentry/nextjs';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { v4 as uuid } from 'uuid';

import PackageJson from 'package.json';

import * as Gtag from 'clients/Gtag';
import EscapodApi from 'clients/Escapod';
import Fbq from 'clients/Fbq';
import base64 from 'utils/base64';
import formatInterestLabel from 'utils/formatInterestLabel';
import {
  CustomerInterestLevel,
  ProductLine,
  ProductLineModification,
  ProductLineModificationGroup,
  ProductLinePackage,
  Topo2Trim
} from 'escapod';
import { TImageText } from 'components/blocks/ImageText';
import { TProductCards } from 'components/blocks/ProductCards';

import localforage from 'localforage';
import { useAppSettings } from './AppSettingsContext';
import Klaviyo from 'clients/Klaviyo/browser';
import priceFromRules from 'utils/priceFromRules';

export const STORAGE_KEY = `__ESCAPOD_BUILDER_${PackageJson.version}`;

export type BuilderCustomer = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  message: string;
  newsletterOptIn: boolean;
  financing: boolean;
  interest: CustomerInterestLevel;
};

type TBuilderMutate = {
  setCustomer: (customer: BuilderCustomer) => void;
  setConfirmationContent: (confirmationContent: (TImageText | TProductCards)[]) => void;
  setCustomerErrors: (errors: Partial<BuilderCustomer & { form?: string }>) => void;
  selectTrailer: (id: string, confirmationContent: (TImageText | TProductCards)[]) => void;
  selectTrim: (trim: Topo2Trim) => void;
  addModification: (
    modification: ProductLineModification | ProductLinePackage,
    remove?: string[]
  ) => void;
  removeModification: (modification: ProductLineModification | ProductLinePackage) => void;
  conflictsForModification: (
    modification: ProductLineModification | ProductLinePackage
  ) => (ProductLineModification | ProductLinePackage)[];
  selectVariantOptions: (variantOptions: { [key: string]: string }) => void;
  unmetDependenciesForModification: (
    modification: ProductLineModification | ProductLinePackage
  ) => (ProductLineModification | ProductLinePackage)[];
  selectedDependentsForModification: (
    modification: ProductLineModification | ProductLinePackage
  ) => (ProductLineModification | ProductLinePackage)[];
  setIsLoading: (isLoading: boolean) => void;
  setPaymentErrors: (errors: { form?: string }) => void;
  submit: () => Promise<void>;
  reserve: () => Promise<void>;
};
type TBuilderContext = {
  customer: BuilderCustomer;
  customerErrors: Partial<BuilderCustomer & { form?: string }>;
  confirmationContent: (TImageText | TProductCards)[];
  trailers: ProductLine[];
  selectedTrailer: ProductLine | null;
  selectedTrim: Topo2Trim | null;
  selectedVariantOptions: { [key: string]: string };
  modifications: (ProductLineModification | ProductLinePackage)[];
  total: number;
  estimatedMonthlyPayment: number;
  isLoading: boolean;
  paymentErrors: { form?: string };
};

const INITIAL_STATE: TBuilderContext = {
  customer: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    message: '',
    newsletterOptIn: true,
    financing: true,
    interest: 'quote'
  },
  selectedTrim: null,
  confirmationContent: [],
  customerErrors: {},
  trailers: [],
  selectedTrailer: null,
  selectedVariantOptions: {},
  modifications: [],
  total: 0,
  estimatedMonthlyPayment: 0,
  isLoading: false,
  paymentErrors: {}
};

export const BuilderContext = createContext(INITIAL_STATE);
export const BuilderMutate = createContext<TBuilderMutate | null>(null);

export const BuilderContextProvider: FC = ({ children }) => {
  const [context, setContext] = useState(INITIAL_STATE);
  const _trim = context.selectedTrim;
  const stripe = useStripe();
  const elements = useElements();
  const settingsContext = useAppSettings();

  // TO-DO: Set as computed value
  const _getTotal = useCallback(
    (
      trailer: ProductLine | null,
      mods: (ProductLineModification | ProductLinePackage)[],
      selectedTrim?: string,
      selectedVariantOptions?: { [key: string]: string }
    ) => {
      const __trim = selectedTrim || _trim; // TO-DO: Rename
      const trailerPrice =
        trailer?.trims?.find(trim => trim.name === __trim)?.price || trailer?.price || 0;

      const variantPrice = Object.keys(selectedVariantOptions || {}).reduce((total, group) => {
        const variantGroup = trailer?.variantGroups_2024?.find(_group => _group.name === group);
        const variant = variantGroup?.variants.find(
          variant => variant.name === (selectedVariantOptions || {})[group]
        );
        return total + priceFromRules(variant?.priceRules || [], { trim: __trim || '' }, 0);
      }, 0);
      return mods.reduce((total, mod) => {
        const price = mod.priceRules
          ? priceFromRules(mod.priceRules || [], { trim: __trim || '' })
          : mod.price;
        return total + price;
      }, trailerPrice + variantPrice);
    },
    [_trim]
  );
  const _getMonthlyPayment = useCallback(
    (total: number) => {
      // const downPayment = settingsContext.settings?.financing.downPayment || 0 || 13000;
      const downPayment = total * 0.2;
      const term = settingsContext.settings?.financing.term || 0 || 15;
      const rate = settingsContext.settings?.financing.interestRate || 0 || 7.99;
      const interest = Math.pow(1 + rate / 100 / 12, term * 12);

      const monthly = Math.floor(
        ((total - downPayment) * interest * (rate / 100 / 12)) / (interest - 1)
      );
      console.log('Monthly Calculator');
      console.table({
        total,
        downPayment,
        term,
        rate,
        'monthly interest': interest,
        'monthly payment': monthly
      });
      return monthly;
    },
    [settingsContext]
  );

  const _setTrailers = (trailers: TBuilderContext['trailers']) =>
    setContext({ ...context, trailers });
  const _initialize = async () => _setTrailers(await EscapodApi.trailers.fetch());

  useEffect(() => {
    if (!context.selectedTrailer) return;
    const persist = async () => await localforage.setItem(STORAGE_KEY, JSON.stringify(context));
    persist();
  }, [context]);

  useEffect(() => {
    const rehydrate = async () => {
      const persistedContextString: string | null = await localforage.getItem(STORAGE_KEY);
      const persistedContext: TBuilderContext = !!persistedContextString
        ? JSON.parse(persistedContextString)
        : INITIAL_STATE;

      if (!!persistedContext?.selectedTrailer) setContext(persistedContext);
    };

    rehydrate();
  }, []);

  const mutate: TBuilderMutate = useMemo(() => {
    return {
      selectTrailer: async (id, confirmationContent) => {
        if (id === context?.selectedTrailer?._id) return;

        const persistedContextString: string | null = await localforage.getItem(STORAGE_KEY);
        const persistedContext: TBuilderContext = !!persistedContextString
          ? JSON.parse(persistedContextString)
          : INITIAL_STATE;

        if (!!id && id === persistedContext?.selectedTrailer?._id) {
          return setContext(persistedContext);
        }

        const selectedTrailer = context.trailers.find(trailer => trailer._id === id) || null;
        const firstNonExclusiveOptions = selectedTrailer?.variantGroups_2024?.reduce(
          (selectedOptions, group) => {
            if (group.exclusiveVariantGroup) return selectedOptions;

            return {
              ...selectedOptions,
              [group.name]: group.variants[0].name
            };
          },
          {}
        );

        // const firstExclusiveVariant = selectedTrailer?.variantGroups_2024?.find(
        //   group => group.exclusiveVariantGroup
        // );
        // const firstOptions = !!firstExclusiveVariant
        const firstOptions = firstNonExclusiveOptions;
        const total = _getTotal(selectedTrailer, context.modifications);

        setContext({
          ...context,
          selectedTrailer,
          selectedVariantOptions: firstOptions || {},
          selectedTrim: 'Voyager',
          modifications: [],
          total,
          estimatedMonthlyPayment: _getMonthlyPayment(total),
          confirmationContent
        });
      },
      selectTrim: trim => {
        const total = _getTotal(context.selectedTrailer, [], trim, context.selectedVariantOptions);
        setContext(context => ({
          ...context,
          selectedTrim: trim,
          modifications: [],
          total,
          estimatedMonthlyPayment: _getMonthlyPayment(total)
        }));
      },
      setConfirmationContent: confirmationContent => {
        setContext({ ...context, confirmationContent });
      },
      addModification: (mod, others) => {
        setContext(state => {
          const total = _getTotal(
            state.selectedTrailer,
            [...state.modifications, mod],
            state.selectedTrim || undefined,
            state.selectedVariantOptions
          );
          const price = mod.priceRules
            ? priceFromRules(mod.priceRules || [], { trim: state.selectedTrim || '' }, mod.price)
            : mod.price;
          const isStandard = 'isStandard' in mod && mod.isStandard && price === 0;
          const modifications = state.modifications
            .filter(_mod => {
              return (mod.name !== _mod.name && mod._key !== _mod._key) || mod.sku !== _mod.sku;
            })
            .filter(_mod => !others?.length || !others?.includes(_mod.sku))
            .concat(isStandard ? [] : [mod]);

          return {
            ...state,
            modifications,
            total,
            estimatedMonthlyPayment: _getMonthlyPayment(total)
          };
        });
      },
      removeModification: mod => {
        setContext(state => {
          const modifications = state.modifications.filter(
            modification => modification.sku !== mod.sku
          );

          const total = _getTotal(
            state.selectedTrailer,
            modifications,
            state.selectedTrim || undefined,
            state.selectedVariantOptions
          );

          return {
            ...state,
            modifications,
            total,
            estimatedMonthlyPayment: _getMonthlyPayment(total)
          };
        });
      },
      selectVariantOptions: variantOptions => {
        const variantOptionsAreExclusive = Object.keys(variantOptions).some(option => {
          return !!context.selectedTrailer?.variantGroups_2024?.find(group => group.name === option)
            ?.exclusiveVariantGroup;
        });

        const nonExclusiveVariantOptions = Object.entries(context.selectedVariantOptions).reduce(
          (nonExclusiveVariantOptions, [option, value]) => {
            const optionIsExclusive = context.selectedTrailer?.variantGroups_2024?.find(
              group => group.name === option
            )?.exclusiveVariantGroup;

            return optionIsExclusive
              ? nonExclusiveVariantOptions
              : { ...nonExclusiveVariantOptions, [option]: value };
          },
          {}
        );

        const firstOptions = context.selectedTrailer?.variantGroups_2024?.reduce(
          (selectedOptions, group) => {
            if (group.exclusiveVariantGroup) return selectedOptions;

            return {
              ...selectedOptions,
              [group.name]: group.variants[0].name
            };
          },
          {}
        );

        setContext({
          ...context,
          total: _getTotal(
            context.selectedTrailer,
            context.modifications,
            context.selectedTrim || undefined,
            variantOptionsAreExclusive
              ? variantOptions
              : { ...firstOptions, ...nonExclusiveVariantOptions, ...variantOptions }
          ),
          selectedVariantOptions: variantOptionsAreExclusive
            ? variantOptions
            : { ...firstOptions, ...nonExclusiveVariantOptions, ...variantOptions }
        });
      },
      conflictsForModification: mod =>
        context.modifications.filter(
          modification => 'conflicts' in mod && mod.conflicts?.includes(modification.sku)
        ) || [],
      unmetDependenciesForModification: mod => {
        if (!context.selectedTrailer) return [];

        const selectedModsBySku = context.modifications.map(modification => modification.sku);
        const trailerModifications = context.selectedTrailer.modificationGroups.reduce(
          (flattenedMods: ProductLineModification[], group: ProductLineModificationGroup) => [
            ...flattenedMods,
            ...group.modifications
          ],
          context.selectedTrailer?.packages
        );

        const unmetSkus =
          ('dependencies' in mod &&
            mod.dependencies?.filter(dep => {
              return dep.includes(':')
                ? context.selectedTrim === dep.split(':')[0]
                  ? !selectedModsBySku.includes(dep.split(':')[1])
                  : false
                : !selectedModsBySku.includes(dep);
            })) ||
          [];

        return trailerModifications.filter(mod =>
          unmetSkus.find(sku =>
            sku.includes(':') ? sku.split(':')[1] === mod.sku : sku === mod.sku
          )
        );
      },
      selectedDependentsForModification: mod => {
        if (!context.selectedTrailer) return [];

        const trailerModifications = context.selectedTrailer.modificationGroups.reduce(
          (flattenedMods: ProductLineModification[], group: ProductLineModificationGroup) => [
            ...flattenedMods,
            ...group.modifications
          ],
          context.selectedTrailer?.packages
        );
        const dependents = trailerModifications
          .filter((modification: ProductLineModification) => {
            const trimDeps =
              modification.dependencies
                ?.filter(dep => dep.includes(':'))
                ?.map(dep => ({ trim: dep.split(':')[0], value: dep.split(':')[1] }))
                ?.filter(trimDep => trimDep.trim === context.selectedTrim)
                ?.map(trimDep => trimDep.value) || [];

            const mods = trimDeps.concat(
              modification.dependencies?.filter(dep => !dep.includes(':')) || []
            );

            return mods.includes(mod.sku);
          })
          .map(modification => modification.sku);

        return context.modifications.filter(modification => dependents.includes(modification.sku));
      },
      setCustomer: customer => setContext(context => ({ ...context, customer })),
      setCustomerErrors: errors => setContext(context => ({ ...context, customerErrors: errors })),
      setPaymentErrors: errors => setContext(context => ({ ...context, paymentErrors: errors })),
      setIsLoading: isLoading => setContext(context => ({ ...context, isLoading })),
      submit: async () => {
        const {
          selectedTrailer,
          selectedTrim,
          estimatedMonthlyPayment,
          customer,
          selectedVariantOptions,
          total,
          modifications
        } = context;

        if (!selectedTrailer || !customer.interest) return;

        // TO-DO: Create a helper for this
        const basePrice = selectedTrailer?.trims?.find(trim => trim.name === selectedTrim)?.price;
        const image = selectedTrailer.images?.find(image => {
          const unmatchedVariants = [selectedTrim, ...Object.values(selectedVariantOptions)].reduce(
            (unmatchedVariants, option) => {
              const firstMatch = unmatchedVariants.findIndex(unmatched => option === unmatched);

              return unmatchedVariants.filter((_, i) => i !== firstMatch);
            },
            image.values
          );

          return unmatchedVariants.length === 0;
        });

        Sentry.setUser({ email: customer.email });

        const request = {
          templateParams: {
            FIRST_NAME: customer.firstName,
            LAST_NAME: customer.lastName,
            EMAIL: customer.email,
            PHONE: customer.phone,
            ADDRESS: customer.address,
            CITY: customer.city,
            STATE: customer.state,
            ZIP: customer.zip,
            MESSAGE: customer.message,
            NEWSLETTER_OPT_IN: customer.newsletterOptIn,
            FINANCING: customer.financing,
            MONTHLY_PAYMENT: estimatedMonthlyPayment,
            INTEREST: formatInterestLabel(customer.interest),
            LEAD_TIME: selectedTrailer.leadTime,
            IMAGE: image?.src || '',
            TRAILER_NAME: selectedTrailer.name,
            TRAILER_BASE_PRICE:
              basePrice +
              Object.keys(selectedVariantOptions).reduce((total, group) => {
                const price = priceFromRules(
                  selectedTrailer.variantGroups_2024
                    ?.find(_group => _group.name === group)
                    ?.variants.find(variant => variant.name === selectedVariantOptions[group])
                    ?.priceRules || [],
                  { trim: selectedTrim || '' },
                  0
                );

                return total + price;
              }, 0),
            TRAILER_TOTAL_PRICE: total.toString(),
            TRAILER_VARIANTS: Object.values(selectedVariantOptions).join(' / '),
            TRAILER_TRIM: selectedTrim,
            TRAILER_MODIFICATIONS: modifications // TO-DO: Use proper templating engine for this
              .map(
                modification =>
                  `${modification.name} - $${
                    modification.priceRules
                      ? priceFromRules(
                          modification.priceRules || [],
                          { trim: selectedTrim || '' },
                          modification.price
                        )
                      : modification.price
                  }<br>`
              )
              .join(''),

            CHANNEL: '',
            CAMPAIGN: '',
            AD_SET: '',
            AD: '',
            PLACEMENT: '',
            FBCLID: ''
          },
          items: [
            {
              _key: uuid(),
              _type: 'lineItem',
              name: `${selectedTrailer.name} ${selectedTrim}`,
              quantity: 1,
              price: (
                selectedTrailer.trims?.find(trim => selectedTrim === trim.name)?.price +
                Object.keys(selectedVariantOptions).reduce((total, group) => {
                  const price = priceFromRules(
                    selectedTrailer.variantGroups_2024
                      ?.find(_group => _group.name === group)
                      ?.variants.find(variant => variant.name === selectedVariantOptions[group])
                      ?.priceRules || [],
                    { trim: selectedTrim || '' },
                    0
                  );

                  return total + price;
                }, 0)
              ).toString(),
              note: Object.values(selectedVariantOptions).join(' / ')
            },
            ...modifications.map(mod => ({
              _key: uuid(),
              _type: 'lineItem',
              quantity: 1,
              price: mod.priceRules
                ? priceFromRules(mod.priceRules || [], { trim: selectedTrim || '' }, mod.price)
                : mod.price,
              name: mod.name,
              sku: mod.sku
            }))
          ]
        };

        if (customer.newsletterOptIn) {
          Fbq('Subscribe', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
          EscapodApi.klaviyo
            .subscribe({
              firstName: customer.firstName,
              lastName: customer.lastName,
              email: customer.email,
              form: 'quote_submission'
            })
            .catch(console.error);
          Gtag.event('conversion', {
            send_to: 'AW-814232277/VfPlCMato6YDENXloIQD',
            transaction_id: window.btoa(customer.email),
            event_callback: () => {
              return;
            }
          });
        }

        return EscapodApi.quotes
          .create(request)
          .then(() => {
            if (
              process.env.NODE_ENV !== 'development' &&
              process.env.NEXT_PUBLIC_SANITY_DATASET !== 'staging'
            ) {
              Fbq('Lead');
              Gtag.event('conversion', {
                send_to: 'AW-814232277/RSlICPqunakYENXloIQD',
                transaction_id: window.btoa(customer.email),
                event_callback: () => {
                  return;
                }
              });
              Klaviyo.events.quote({
                product: request.templateParams.TRAILER_NAME,
                modifications: request.templateParams.TRAILER_MODIFICATIONS.split(', '),
                modificationValue:
                  parseInt(request.templateParams.TRAILER_TOTAL_PRICE) -
                  parseInt(request.templateParams.TRAILER_BASE_PRICE),
                variants: request.templateParams.TRAILER_VARIANTS.split(', '),
                image: request.templateParams.IMAGE,
                price: parseInt(request.templateParams.TRAILER_TOTAL_PRICE)
              });
            }
          })
          .catch(err => {
            Sentry.withScope(scope => {
              scope.setExtra('request', request);
              Sentry.captureException(err);
            });
            setContext(context => ({
              ...context,
              customerErrors: {
                form: 'There was an unexpected error while submitting your quote. Please contact sales@escapod.us directly or try again later.'
              }
            }));
          })
          .finally(() => {
            setContext(context => ({ ...context, isLoading: false }));
            setTimeout(() => localforage.removeItem(STORAGE_KEY), 0);
          });
      },
      reserve: async () => {
        const {
          selectedTrailer,
          selectedTrim,
          estimatedMonthlyPayment,
          customer,
          modifications,
          total,
          selectedVariantOptions
        } = context;
        if (!stripe || !elements || !selectedTrailer || !customer.interest) return;

        const card = elements.getElement(CardElement);
        if (!card) {
          return setContext(context => ({
            ...context,
            isLoading: true,
            paymentErrors: { form: `Please enter required card data.` }
          }));
        }

        const image = selectedTrailer.images?.find(image => {
          const unmatchedVariants = Object.values(selectedVariantOptions).reduce(
            (unmatchedVariants, option) => {
              const firstMatch = unmatchedVariants.findIndex(unmatched => option === unmatched);

              return unmatchedVariants.filter((_, i) => i !== firstMatch);
            },
            image.values
          );

          return unmatchedVariants.length === 0;
        });

        setContext(context => ({ ...context, isLoading: true }));

        const result = await stripe.createToken(card).catch(err => {
          Sentry.captureException(err);
          setContext(context => ({
            ...context,
            isLoading: true,
            paymentErrors: {
              form: `Unexpected Error: We could not complete your reservation. You may contact sales@escapod.us directly or try again later.`
            }
          }));
        });

        if (!result?.token) {
          return setContext(context => ({
            ...context,
            isLoading: true,
            paymentErrors: { form: `Please enter required card data.` }
          }));
        }

        Sentry.setUser({ email: customer.email });

        const request = {
          stripeChargeId: result.token.id,
          templateParams: {
            FIRST_NAME: customer.firstName,
            LAST_NAME: customer.lastName,
            EMAIL: customer.email,
            PHONE: customer.phone,
            ADDRESS: customer.address,
            CITY: customer.city,
            STATE: customer.state,
            ZIP: customer.zip,
            MESSAGE: customer.message,
            NEWSLETTER_OPT_IN: customer.newsletterOptIn,
            FINANCING: customer.financing,
            MONTHLY_PAYMENT: estimatedMonthlyPayment,
            INTEREST: formatInterestLabel('reserve'),
            LEAD_TIME: selectedTrailer.leadTime,
            IMAGE: image?.src || '',
            TRAILER_NAME: selectedTrailer.name,
            TRAILER_BASE_PRICE: selectedTrailer.price.toString(),
            TRAILER_TOTAL_PRICE: total.toString(),
            TRAILER_TRIM: selectedTrim,
            TRAILER_VARIANTS: Object.values(selectedVariantOptions).join(' / '),
            TRAILER_MODIFICATIONS: modifications // TO-DO: Use proper templating engine for this
              .map(modification => `${modification.name} - $${modification.price}<br>`)
              .join(''),

            CHANNEL: '',
            CAMPAIGN: '',
            AD_SET: '',
            AD: '',
            PLACEMENT: '',
            FBCLID: ''
          },
          items: modifications
        };

        if (customer.newsletterOptIn) {
          Fbq('Subscribe', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
          EscapodApi.klaviyo
            .subscribe({
              firstName: customer.firstName,
              lastName: customer.lastName,
              email: customer.email,
              form: 'reservation_submission'
            })
            .catch(console.error);
          Gtag.event('conversion', {
            send_to: 'AW-814232277/VfPlCMato6YDENXloIQD',
            transaction_id: window.btoa(customer.email),
            event_callback: () => {
              return;
            }
          });
          Klaviyo.events.reservation({
            product: request.templateParams.TRAILER_NAME,
            modifications: request.templateParams.TRAILER_MODIFICATIONS.split(', '),
            modificationValue:
              parseInt(request.templateParams.TRAILER_TOTAL_PRICE) -
              parseInt(request.templateParams.TRAILER_BASE_PRICE),
            variants: request.templateParams.TRAILER_VARIANTS.split(', '),
            image: request.templateParams.IMAGE,
            price: parseInt(request.templateParams.TRAILER_TOTAL_PRICE)
          });
        }

        Sentry.withScope(scope => {
          scope.setExtra('params', request);
          Sentry.captureMessage(`Reservation Pre-flight Log`);
        });

        EscapodApi.reservations
          .create(request)
          .then(res => {
            Sentry.withScope(scope => {
              scope.setExtra('response', res);
              Sentry.captureMessage(`Reservation Post-flight Log`);
            });

            if (process.env.NEXT_PUBLIC_SANITY_DATASET !== 'staging') {
              Fbq('Lead');
              Gtag.event('conversion', {
                send_to: 'AW-814232277/ccFdCL7bqKYDENXloIQD',
                transaction_id: base64(customer.email),
                event_callback: () => {
                  return;
                }
              });
            }
          })
          .catch(err => {
            Sentry.withScope(scope => {
              scope.setExtra('request', request);
              scope.setExtra('stripe_result', result);
              Sentry.captureException(err);
            });
            setContext(context => ({
              ...context,
              customerErrors: {
                form: 'There was an unexpected error while submitting your quote. Please contact sales@escapod.us directly or try again later.'
              }
            }));
          })
          .finally(() => {
            setContext(context => ({ ...context, isLoading: false }));
            setTimeout(() => localforage.removeItem(STORAGE_KEY), 0);
          });
      }
    };
  }, [context]);

  useEffect(() => {
    _initialize();
  }, []);

  return (
    <BuilderContext.Provider value={context}>
      <BuilderMutate.Provider value={mutate}>{children}</BuilderMutate.Provider>
    </BuilderContext.Provider>
  );
};

export const useBuilderContext = () => {
  const builderContext = useContext(BuilderContext);

  if (builderContext === undefined) {
    throw new Error('useBuilderContext must be used within a BuilderContextProvider');
  }

  return builderContext;
};

export const useBuilderMutate = () => {
  const mutate = useContext(BuilderMutate);

  if (mutate === undefined) {
    throw new Error('useBuilderMutate must be used within a BuilderContextProvider');
  }

  return mutate;
};

export default BuilderContextProvider;
