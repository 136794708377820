import { createContext, FC, useContext, useState, useEffect } from 'react';
import { AppSettings } from 'types';

export type TAppSettingsContext = {
  settings: AppSettings | null;
  setContext: (context: TAppSettingsContext) => void;
};

const INITIAL_CONTEXT: TAppSettingsContext = {
  settings: null,
  setContext: () => {}
};

export const AppSettingsContext = createContext<TAppSettingsContext>(INITIAL_CONTEXT);

export const AppSettingsContextProvider: FC = ({ children }) => {
  const [context, setContext] = useState<TAppSettingsContext>(INITIAL_CONTEXT);

  return (
    <AppSettingsContext.Provider value={{ ...context, setContext }}>
      {children}
    </AppSettingsContext.Provider>
  );
};

export const useAppSettings = () => {
  const context = useContext(AppSettingsContext);

  if (context === undefined) {
    throw new Error('useAppSettings must be used within AppSettingsContextProvider');
  }
  return context;
};
