import axios from 'axios';
import * as Gtag from 'clients/Gtag';
import { Listing, ListingAvailability } from 'clients/Outdoorsy';
import { Location, GeoJSON } from 'types';

const BASE_URL = '/api';

const VERSION = 'v1';
const API_URL = `${BASE_URL}/${VERSION}`;

const EscapodApi = {
  outdoorsy: {
    availability: async (id: string): Promise<ListingAvailability[]> =>
      (await axios.get(`${API_URL}/outdoorsy/availability?id=${id}`)).data,
    trailers: async (): Promise<Listing[]> =>
      (await axios.get(`${API_URL}/outdoorsy/trailers`)).data
  },
  rentals: {
    createBooking: async (data: {
      templateParams: {
        FIRST_NAME: string;
        LAST_NAME: string;
        EMAIL: string;
        PHONE: string;
        FROM_DATE: string;
        TO_DATE: string;
        TOTAL: number;
        NIGHTS: number;
      };
    }): Promise<any> => {
      return (await axios.post(`${API_URL}/rentals/bookings/create`, data)).data;
    }
  },
  klaviyo: {
    subscribe: async (data: {
      listId?: string;
      email: string;
      firstName?: string;
      lastName?: string;
      phone?: string;
      form?: string;
    }) => {
      Gtag.event('newsletter_signup', {
        path: window?.location.pathname,
        form: data.form
      });
      return await axios.post(`${API_URL}/klaviyo/subscribe`, data);
    }
  },
  contact: {
    create: async (data: {
      templateParams: {
        FIRST_NAME: string;
        LAST_NAME: string;
        EMAIL: string;
        PHONE: string;
        SUBJECT: string;
        MESSAGE: string;
      };
    }) => (await axios.post(`${API_URL}/contact/create`, data)).data
  },
  trailers: {
    fetch: async (): Promise<any[]> => (await axios.get(`${API_URL}/trailers`)).data
  },
  locations: {
    fetch: async (): Promise<Location[]> => (await axios.get(`${API_URL}/locations`)).data,
    finderRequest: async (data: {
      templateParams: {
        NAME: string;
        EMAIL: string;
        LOCATION: string;
        LAT: number;
        LNG: number;
      };
    }) => {
      Gtag.event('finder_request', { path: window?.location.pathname });

      return (await axios.post(`${API_URL}/locations/request`, data)).data;
    },
    eventAttendanceRequest: async (data: {
      templateParams: {
        NAME: string;
        EMAIL: string;
        MESSAGE: string;
        EVENT: string;
        INTENT: string;
      };
    }) => {
      return (await axios.post(`${API_URL}/locations/event_attendance_request`, data)).data;
    },
    interest: async (data: {
      name: string;
      email: string;
      location: string;
      lat: number;
      lng: number;
    }) => {
      Gtag.event('finder_select_location', { path: window?.location.pathname });

      return (await axios.post(`${API_URL}/locations/interest`, data)).data;
    },
    geolocate: async (search: string): Promise<GeoJSON[]> =>
      (await axios.get(`${API_URL}/locations/geolocate?search=${search}`)).data,
    viewingRequest: async (data: {
      templateParams: {
        NAME: string;
        EMAIL: string;
        PHONE: string;
        ZIP: string;
        DESIRED_RADIUS: string;
        SELECTION: string;
      };
    }): Promise<object> => (await axios.post(`${API_URL}/locations/viewing_request`, data)).data
  },
  quotes: {
    create: async (data: {
      templateParams: {
        FIRST_NAME: string;
        LAST_NAME: string;
        EMAIL: string;
        PHONE: string;
        ADDRESS: string;
        CITY: string;
        STATE: string;
        ZIP: string;
        MESSAGE: string;
        IMAGE: string;
        INTEREST: string;
        LEAD_TIME: string;
        TRAILER_NAME: string;
        TRAILER_BASE_PRICE: string;
        TRAILER_TOTAL_PRICE: string;
        TRAILER_VARIANTS: string;
        TRAILER_MODIFICATIONS: string;
      };
    }) => {
      Gtag.event('builder_form_submission', {
        path: window?.location.pathname
      });

      return (await axios.post(`${API_URL}/quotes/create`, data)).data;
    }
  },
  reservations: {
    create: async (data: {
      stripeChargeId: string;
      templateParams: {
        FIRST_NAME: string;
        LAST_NAME: string;
        EMAIL: string;
        PHONE: string;
        ADDRESS: string;
        CITY: string;
        STATE: string;
        ZIP: string;
        MESSAGE: string;
        IMAGE: string;
        INTEREST: string;
        LEAD_TIME: string;
        TRAILER_NAME: string;
        TRAILER_BASE_PRICE: string;
        TRAILER_TOTAL_PRICE: string;
        TRAILER_VARIANTS: string;
        TRAILER_MODIFICATIONS: string;
      };
    }) => (await axios.post(`${API_URL}/reservations/create`, data)).data
  },
  orders: {
    verify: async (id: string, email: string) =>
      await (
        await axios.post(`${API_URL}/orders/verify`, { id, email })
      ).data,
    payments: {
      create: async (id: string, stripeChargeId: string) =>
        (await axios.post(`${API_URL}/orders/payments/create`, { id, stripeChargeId })).data
    }
  }
};

export default EscapodApi;
