import React, { FC, createContext, useState, useContext, useCallback } from 'react';
import { BuilderCustomer } from 'contexts/BuilderContext';

export type PopupCustomer = Omit<BuilderCustomer, 'newsletterOptIn' | 'financing' | 'interest'>;
type TPopupContext = {
  isActive: boolean;
  customer: PopupCustomer;
  setIsActive: (isActive: boolean) => void;
  setCustomer: (customer: PopupCustomer) => void;
};
const INITIAL_CONTEXT: TPopupContext = {
  isActive: false,
  customer: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    message: ''
  },

  setIsActive: () => {},
  setCustomer: () => {}
};

export const ChatPopupContext = createContext(INITIAL_CONTEXT);
export const ChatPopupContextProvider: FC = ({ children }) => {
  const [context, setContext] = useState(INITIAL_CONTEXT);

  const setIsActive = useCallback(
    (isActive: boolean) => setContext(context => ({ ...context, isActive })),
    []
  );
  const setCustomer = useCallback(
    (customer: PopupCustomer) => setContext(context => ({ ...context, customer })),
    []
  );

  return (
    <ChatPopupContext.Provider value={{ ...context, setIsActive, setCustomer }}>
      {children}
    </ChatPopupContext.Provider>
  );
};

export const useChatPopupContext = () => {
  const chatPopupContext = useContext(ChatPopupContext);

  if (chatPopupContext === undefined) {
    throw new Error('ChatPopupContext must be used within a ChatPopupContextProvider');
  }

  return chatPopupContext;
};

export default ChatPopupContext;
