import { Result } from 'contexts/FinancingContext';

type QuoteEvent = {
  product: string;
  variants: string[];
  modifications: string[];
  image: string;
  price: number;
  modificationValue: number;
};

type FinancingAppEvent = {
  resultType: Result['result'];
  resultStatus: Result['status'];
  downPayment: number;
  monthlyTarget: number;
};

type ContactEvent = {
  name: string;
  phone: string;
  message: string;
  subject: string;
};

type ViewingRequest = {
  name: string;
  phone: string;
  zip: string;
  desiredRadius: string;
};

type EventAttendanceRequest = {
  name: string;
  event: string;
  intent: boolean;
};

const Klaviyo = {
  push: (event: [string, string, any]) => {
    const client =
      typeof window !== 'undefined' && !!(window as any).klaviyo ? (window as any).klaviyo : {};

    return client?.push(event) || (() => {});
  },
  events: {
    quote: (quote: QuoteEvent) => Klaviyo.push(['track', 'Submitted Quote', quote]),
    viewingRequest: (request: ViewingRequest) =>
      Klaviyo.push(['track', 'Submitted Viewing Request', request]),
    eventAttendance: (request: EventAttendanceRequest) =>
      Klaviyo.push(['track', 'Submitted Viewing Request', request]),
    reservation: (reservation: QuoteEvent) =>
      Klaviyo.push(['track', 'Submitted Reservation', reservation]),
    financingApp: (app: FinancingAppEvent) =>
      Klaviyo.push(['track', 'Submitted Pre-Approval App', app]),
    contact: (app: ContactEvent) => Klaviyo.push(['track', 'Submitted Contact Form', app])
  }
};

export default Klaviyo;
