import { gql } from 'graphql-request';

const Gql = {
  products: gql`
    {
      products(first: 250) {
        edges {
          node {
            availableForSale
            handle
            id
            productType
            tags
            title
            vendor
            images(first: 10) {
              edges {
                node {
                  altText
                  id
                  originalSrc
                }
              }
            }
            variants(first: 10) {
              edges {
                node {
                  id
                  compareAtPrice {
                    amount
                    currencyCode
                  }
                  availableForSale
                  title
                  price {
                    amount
                    currencyCode
                  }
                  image {
                    id
                    altText
                    originalSrc
                  }
                  product {
                    id
                    handle
                  }
                  title
                  selectedOptions {
                    name
                    value
                  }
                }
              }
            }
            options {
              id
              name
              values
            }
          }
        }
      }
    }
  `,
  checkoutFetch: gql`
    query checkoutFetch($id: ID!) {
      node(id: $id) {
        ... on Checkout {
          id
          completedAt
          currencyCode
          note
          subtotalPrice {
            amount
            currencyCode
          }
          totalPrice {
            amount
            currencyCode
          }
          webUrl
          lineItems(first: 250) {
            edges {
              node {
                customAttributes {
                  key
                  value
                }
                id
                quantity
                title
                variant {
                  id
                  compareAtPrice {
                    amount
                    currencyCode
                  }
                  availableForSale
                  title
                  price {
                    amount
                    currencyCode
                  }
                  image {
                    id
                    altText
                    originalSrc
                  }
                  product {
                    id
                    handle
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  checkoutCreate: gql`
    mutation checkoutCreate($input: CheckoutCreateInput!) {
      checkoutCreate(input: $input) {
        userErrors {
          field
          message
        }
        checkout {
          id
          completedAt
          currencyCode
          note
          subtotalPrice {
            amount
            currencyCode
          }
          totalPrice {
            amount
            currencyCode
          }
          webUrl
          lineItems(first: 250) {
            edges {
              node {
                customAttributes {
                  key
                  value
                }
                id
                quantity
                title
                variant {
                  id
                  compareAtPrice {
                    amount
                    currencyCode
                  }
                  availableForSale
                  title
                  price {
                    amount
                    currencyCode
                  }
                  image {
                    id
                    altText
                    originalSrc
                  }
                  product {
                    id
                    handle
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  checkoutLineItemsAdd: gql`
    mutation checkoutLineItemsAdd($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) {
      checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
        userErrors {
          field
          message
        }
        checkout {
          id
          completedAt
          currencyCode
          note
          subtotalPrice {
            amount
            currencyCode
          }
          totalPrice {
            amount
            currencyCode
          }
          webUrl
          lineItems(first: 250) {
            edges {
              node {
                customAttributes {
                  key
                  value
                }
                id
                quantity
                title
                variant {
                  id
                  compareAtPrice {
                    amount
                    currencyCode
                  }
                  availableForSale
                  title
                  price {
                    amount
                    currencyCode
                  }
                  image {
                    id
                    altText
                    originalSrc
                  }
                  product {
                    id
                    handle
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  checkoutLineItemsRemove: gql`
    mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
      checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
        userErrors {
          field
          message
        }
        checkout {
          id
          completedAt
          currencyCode
          note
          subtotalPrice {
            amount
            currencyCode
          }
          totalPrice {
            amount
            currencyCode
          }
          webUrl
          lineItems(first: 250) {
            edges {
              node {
                customAttributes {
                  key
                  value
                }
                id
                quantity
                title
                variant {
                  id
                  compareAtPrice {
                    amount
                    currencyCode
                  }
                  availableForSale
                  title
                  price {
                    amount
                    currencyCode
                  }
                  image {
                    id
                    altText
                    originalSrc
                  }
                  product {
                    id
                    handle
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  checkoutLineItemsUpdate: gql`
    mutation checkoutLineItemsUpdate(
      $checkoutId: ID!
      $lineItems: [CheckoutLineItemUpdateInput!]!
    ) {
      checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
        userErrors {
          field
          message
        }
        checkout {
          id
          completedAt
          currencyCode
          note
          subtotalPrice {
            amount
            currencyCode
          }
          totalPrice {
            amount
            currencyCode
          }
          webUrl
          lineItems(first: 250) {
            edges {
              node {
                customAttributes {
                  key
                  value
                }
                id
                quantity
                title
                variant {
                  id
                  compareAtPrice {
                    amount
                    currencyCode
                  }
                  availableForSale
                  title
                  price {
                    amount
                    currencyCode
                  }
                  image {
                    id
                    altText
                    originalSrc
                  }
                  product {
                    id
                    handle
                  }
                }
              }
            }
          }
        }
      }
    }
  `,

  customerAssociate: gql`
    mutation checkoutCustomerAssociate($checkoutId: ID!, $customerAccessToken: String!) {
      checkoutCustomerAssociate(
        checkoutId: $checkoutId
        customerAccessToken: $customerAccessToken
      ) {
        userErrors {
          field
          message
        }
        checkout {
          id
        }
      }
    }
  `,

  customerDisassociate: gql`
    mutation checkoutCustomerDisassociate($checkoutId: ID!) {
      checkoutCustomerDisassociate(checkoutId: $checkoutId) {
        userErrors {
          field
          message
        }
        checkout {
          id
        }
      }
    }
  `,

  checkoutAttributesUpdate: gql`
    mutation checkoutAttributesUpdate($checkoutId: ID!, $input: CheckoutAttributesUpdateInput!) {
      checkoutAttributesUpdate(checkoutId: $checkoutId, input: $input) {
        userErrors {
          field
          message
        }
        checkout {
          note
        }
      }
    }
  `
};

export default Gql;
