import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Elements as StripeProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import PackageJson from 'package.json';
import '../styles/globals.css';

import { CartProvider } from 'contexts/CartContext';
import { SearchContextProvider } from 'contexts/SearchContext';
import { NavContextProvider } from 'contexts/NavContext';
import { BuilderContextProvider } from 'contexts/BuilderContext';
import { RentalBookingsContextProvider } from 'contexts/RentalBookingsContext';
import { CompareContextProvider } from 'contexts/CompareContext';
import { NewsletterContextProvider } from 'contexts/NewsletterContext';
import { AppSettingsContextProvider } from 'contexts/AppSettingsContext';
import { FinancingContextProvider } from 'contexts/FinancingContext';
import { ChatPopupContextProvider } from 'contexts/ChatPopupContext';

import * as Gtag from 'clients/Gtag';
import Fbq from 'clients/Fbq';
import captureCampaignData from 'utils/captureCampaignData';

const Stripe = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY || '');

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      Gtag.pageview(url);
      Fbq('ViewContent');
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    console.log(`
      ~~
      escapod.us v${PackageJson.version} initialized

      Hello, Great Outdoors!
      © ${new Date().getFullYear()} Escapod Trailers LLC
      ~~
    `);

    captureCampaignData();

    // purgeStaleContextStorage(PackageJson.version);
  }, []);

  return (
    <StripeProvider stripe={Stripe}>
      <AppSettingsContextProvider>
        <ChatPopupContextProvider>
          <CartProvider>
            <SearchContextProvider>
              <CompareContextProvider>
                <NavContextProvider>
                  <FinancingContextProvider>
                    <BuilderContextProvider>
                      <RentalBookingsContextProvider>
                        <NewsletterContextProvider>
                          <Component {...pageProps} />
                        </NewsletterContextProvider>
                      </RentalBookingsContextProvider>
                    </BuilderContextProvider>
                  </FinancingContextProvider>
                </NavContextProvider>
              </CompareContextProvider>
            </SearchContextProvider>
          </CartProvider>
        </ChatPopupContextProvider>
      </AppSettingsContextProvider>
    </StripeProvider>
  );
};

export default App;
