import React, { FC, createContext, useState, useContext } from 'react';

export type EmploymentStatus =
  | ''
  | 'EMPLOYED'
  | 'HOME_MAKER'
  | 'MILITARY'
  | 'RETIRED'
  | 'SELF_EMPLOYED'
  | 'STUDENT'
  | 'TEMP_STAFFING_AGENCY'
  | 'UNEMPLOYED';

export type Employment = {
  status: EmploymentStatus;
  employer: string;
  employerPhone: string;
  occupation: string;
  salary: number;
  additionalIncome: number;
  additionalIncomeSource: string;
};

export type CreditApplicant = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;

  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;

  ssn: string;
  dob: string;
  usCitizen: boolean;

  employment: Employment;
};

export type Result = {
  status: 'OK' | 'MISS' | 'ERROR';
  result: 'APPROVED' | 'REJECTED' | 'REJECTED_WITH_CONTINGENCY';
};

type State = {
  stage:
    | 'CONSENT'
    | 'BASIC'
    | 'ID_PRIMARY'
    | 'ID_SECONDARY'
    | 'EMPLOYMENT_PRIMARY'
    | 'EMPLOYMENT_SECONDARY'
    | 'REVIEW'
    | 'CONFIRMATION';
  primary: CreditApplicant;
  secondary: CreditApplicant;
  hasSecondary: boolean;
  downPayment: number;
  monthlyTarget: number;
  result: Result | null;
};

type Mutate = {
  setStage: (stage: State['stage']) => void;
  setHasSecondary: (hasSecondary: boolean) => void;
  setApplicant: (applicant: 'primary' | 'secondary', data: Partial<CreditApplicant>) => void;
  setEmployment: (applicant: 'primary' | 'secondary', data: Partial<Employment>) => void;
  setDownPayment: (downPayment: number) => void;
  setMonthlyTarget: (monthlyTarget: number) => void;
  setResult: (result: Result) => void;
};

const INITIAL_EMPLOYMENT = {
  status: '' as EmploymentStatus,
  employer: '',
  employerPhone: '',
  occupation: '',
  salary: 0,
  additionalIncome: 0,
  additionalIncomeSource: ''
};
const INITIAL_APPLICANT = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  ssn: '',
  dob: '',
  usCitizen: false,
  employment: { ...INITIAL_EMPLOYMENT }
};
const INITIAL_STATE: State = {
  stage: 'CONSENT',
  primary: { ...INITIAL_APPLICANT },
  secondary: { ...INITIAL_APPLICANT },
  hasSecondary: false,
  downPayment: 0,
  monthlyTarget: 0,
  result: null
};

export const FinancingContext = createContext<State>(INITIAL_STATE);
export const FinancingMutate = createContext<Mutate | null>(null);

export const FinancingContextProvider: FC = ({ children }) => {
  const [context, setContext] = useState(INITIAL_STATE);

  const mutate: Mutate = {
    setStage: stage => setContext(context => ({ ...context, stage })),
    setHasSecondary: hasSecondary => setContext(context => ({ ...context, hasSecondary })),
    setResult: result => setContext(context => ({ ...context, result })),
    setApplicant: (applicant, data) => {
      setContext(context => ({ ...context, [applicant]: { ...context[applicant], ...data } }));
    },
    setEmployment: (applicant, data) => {
      setContext(context => ({
        ...context,
        [applicant]: {
          ...context[applicant],
          employment: { ...context[applicant]?.employment, ...data }
        }
      }));
    },
    setDownPayment: downPayment => setContext({ ...context, downPayment }),
    setMonthlyTarget: monthlyTarget => setContext({ ...context, monthlyTarget })
  };

  return (
    <FinancingContext.Provider value={context}>
      <FinancingMutate.Provider value={mutate}>{children}</FinancingMutate.Provider>
    </FinancingContext.Provider>
  );
};

export const useFinancingMutate = () => {
  const mutate = useContext(FinancingMutate);

  if (mutate === undefined) {
    throw new Error('useFinancingMutate must be used within a FinancingContextProvider');
  }

  return mutate;
};

export const useFinancingContext = () => {
  const context = useContext(FinancingContext);

  if (context === undefined) {
    throw new Error('useFinancingContext must be used within a FinancingContextProvider');
  }

  return context;
};

export default FinancingContext;
