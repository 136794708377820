import { PriceRule } from 'escapod';

const priceFromRules = (
  rules: PriceRule[],
  context: { [key: string]: string },
  fallback: number = 0
) => {
  const price = rules.find(ruleset => {
    const [key, value] = ruleset.rule.split(':');
    return key in context && context[key] === value;
  })?.price;

  if (typeof price === 'undefined') return fallback;
  return price;
};

export default priceFromRules;
